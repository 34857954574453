.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 0, 128, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-out;
}

.popup-content {
  background: white;
  padding: 40px 50px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.3);
  position: relative;
  text-align: center;
  max-width: 90%;
  width: 450px;
  font-family: Arial, sans-serif;
  border: 2px solid #800080;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255,255,255,0.2);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #800080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 18px;
}

.close-button:hover {
  background: #800080;
  color: white;
  transform: rotate(90deg);
}

.celebration-emoji {
  font-size: 50px;
  animation: bounce 1.2s infinite;
  margin-bottom: 20px;
}

.popup-title {
  color: #800080;
  margin: 0 0 25px 0;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
}

.popup-description {
  color: white;
  margin: 0 0 25px 0;
  font-size: 16px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
  line-height: 1.6;
}

.start-now-button {
  background-color: #800080;
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 12px 40px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
}

.start-now-button:hover {
  background-color: #660066;
  transform: scale(1.05);
}

.top-right-banner {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 999;
  background: linear-gradient(135deg, #800080, #660066);
  padding: 12px 20px;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  animation: slideIn 0.5s ease-out;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid #fff;
  backdrop-filter: blur(4px);
  color: white;
}

.banner-text {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
}

.banner-arrow {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #800080; /* Purple color matching your theme */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  font-size: 24px;
  text-decoration: none;
  font-weight: bold;
}

.banner-arrow:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
  color: #9932CC; /* Lighter purple on hover */
}

@keyframes slideIn {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
}

@media (max-width: 768px) {
  .top-right-banner {
    right: 10px;
    padding: 8px 15px;
    font-size: 12px;
  }

  .popup-content {
    font-size: 14px;
  }
}